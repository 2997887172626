import { chain, extend, find, filter, get, has } from 'lodash';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { getCurrentDomain } from 'common/currentDomain';
import FilterBar from 'common/components/FilterBar';
import { VerticalFilterBar } from 'common/components/FilterBar/VerticalFilterBar';
import FeatureFlags from 'common/feature_flags';
import {
  getDisplayableColumns,
  getFilterableColumns
} from 'common/visualizations/dataProviders/MetadataProvider';
import {
  getFilterConstraints,
  getSanitizeFilterParameterConfig
} from 'common/visualizations/helpers/VifHelpers';

import { setFilters } from '../actions';
import { getVisualizationCanvasColumns, getVisualizationCanvasComputedColumns } from '../selectors';

export function mapStateToProps(state) {
  const { filters, parentView, view, vifs } = state;

  // EN-68996: update this to use parentView.id once we do the db migration
  const parentViewId = FeatureFlags.valueOrDefault('use_vif_dataset_uid_for_vizcan_filters', true) ?
    get(vifs, '[0].series[0].dataSource.datasetUid', parentView.id) : parentView.id;
  const filterConstraints = getFilterConstraints(vifs[0]);

  let dataSource = {
    datasetUid: parentViewId, // EN-68996: update this to use parentView.id once we do the db migration
    // Warning: EN-28544 means we need to always hit the *primary* cname, not an alias.
    // We don't always know what the primary cname is, especially if we've been embedded into a 3rd-party site
    // There's no reliable way to get this today, best fix is to address the issue at the API level.
    domain:
      get(view, 'domainCName') || // Won't work if the view is behind auth and we're looking at an alias.
      get(parentView, 'domainCName') || // Won't work if the view is behind auth.
      getCurrentDomain()
  };

  // Get displayable columns only, subcolumns and system columns are omitted
  const displayableColumns = getDisplayableColumns({
    ...(view || parentView),
    columns: getVisualizationCanvasColumns(state)
  });

  // Filter out unsupported column types
  const columns = getFilterableColumns({ columns: displayableColumns });

  // Get the computed columns
  const allComputedColumns = getVisualizationCanvasComputedColumns(state);
  const computedColumns = chain(allComputedColumns)
    .filter((column) => has(column, 'computationStrategy.parameters.region'))
    .map((column) =>
      extend({}, column, {
        fieldName: column.fieldName,
        name: column.name,
        uid: column.computationStrategy.parameters.region.slice(1)
      })
    )
    .sortBy('name')
    .value();

  const displayableFilters = filter(filters, (fil) => {
    const columnName = fil.columns[0].fieldName;
    return find(columns, ['fieldName', columnName]) || find(computedColumns, ['fieldName', columnName]);
  });

  const filterColumns = { [dataSource.datasetUid]: columns };
  const renderAddFilterButton = { shouldRenderAddFilter: true };
  dataSource = [dataSource];

  return {
    columns: filterColumns,
    computedColumns,
    constraints: filterConstraints,
    filterParameterConfigurations: getSanitizeFilterParameterConfig(displayableFilters, vifs[0]),
    dataSource,
    useForgeStyle: false,
    ...renderAddFilterButton,
    showRemoveButtonInFilterEditor: true
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onUpdate: setFilters }, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { onUpdate: onUpdateOwnProps } = ownProps;
  const { onUpdate: onUpdateDispatchProps } = dispatchProps;

  const mergedOnUpdate = (...args) => {
    onUpdateOwnProps && onUpdateOwnProps(...args);
    onUpdateDispatchProps && onUpdateDispatchProps(...args);
  };

  return { ...ownProps, ...stateProps, ...dispatchProps, onUpdate: mergedOnUpdate };
};

const getFilterBar = (isVertical) => (isVertical ? VerticalFilterBar : FilterBar);

export default {
  Vertical: compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(getFilterBar(true)),
  Horizontal: compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(getFilterBar(false))
};
